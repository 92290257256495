import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Submitting MIPs and Sub-proposals`}</h1>
    <p>{`This page provides all the information you need to understand what kinds of sub-proposals you can submit based on the `}<a parentName="p" {...{
        "href": "../accepted"
      }}>{`currently active MIP set`}</a>{`.`}</p>
    <p>{`It also provides the different templates you'll require, not just for each different kind of sub-proposal, but for submitting entirely new General and Technical MIPs.`}</p>
    <p>{`Note that MIPs 2, 5, 8 and 9 have no process components, hence no subproposals can be generated with them and so they do not appear in the sidebar.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      